import EnergyPalAdvantage from '@/components/FeatureSection/EnergyPalAdvantage';
import FeaturedOn from '@/components/FeatureSection/FeaturedOn';
import FeaturedProducts from '@/components/FeatureSection/FeaturedProducts';
import FindTheRightSolution from '@/components/FeatureSection/FindTheRightSolution';
import GetInTouch from '@/components/FeatureSection/GetInTouch';
import RollingLeadFormSection from '@/components/LeadForm/RollingLeadFormSection';
import VerticalLeadFormSection from '@/components/LeadForm/VerticalLeadFormSection';
import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import FullScreenLanderHero from '@/components/Hero/FullScreenLanderHero';

// components/PostHogScript.js
import Script from 'next/script';

export const PostHogScript = () => (
  <Script
    id="posthog"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
      !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys getNextSurveyStep onSessionId setPersonProperties".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
      posthog.init('phc_3gUTc4iQKDS7ONu35gf0QpWNtgs5R3M4p5w0oVnPUeU',{api_host:'https://us.i.posthog.com', person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
      })
    `,
    }}
  />
);

const Home: NextPage = () => {
  return (
    <>
      <NextSeo
        openGraph={{
          title:
            'Install Solar Panel & Home Battery Systems for Less - EnergyPal',
          description:
            'Power your home for less with EnergyPal: Discover amazing deals on solar panel and home battery systems, compare costs, and install with ease.',
          url: 'https://energypal.com',
          // type: 'article',
          // article: {
          //   authors: ['EnergyPal'],
          //   publishedTime: '2022-04-01T00:00:00Z',
          //   modifiedTime: new Date().toISOString(),
          // },
          images: [
            {
              url: 'https://energypal.com/banners/buy-sunpower-solar-panels-and-batteries-for-less-cost.jpg',
              type: 'image/jpeg',
              width: 2160,
              height: 947,
              alt: 'EnergyPal - Buy SunPower solar panels and batteries for less cost',
            },
          ],
        }}
      />
      <PostHogScript />
      <FullScreenLanderHero />
      <EnergyPalAdvantage />
      <RollingLeadFormSection cid={'elcyjkf45txm5l3g'} />
      <FindTheRightSolution />
      <GetInTouch />
      <FeaturedProducts />
      <FeaturedOn />
      <VerticalLeadFormSection className="bg-white" />
      {/* SPLIT OPTIONS */}
    </>
  );
};

export default Home;
