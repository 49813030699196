import Image from 'next/legacy/image';
import HeroImage2 from 'images/hero/Sunpower-home-cropped.png';
import LeadFormReviewBadge from '../ReviewBadge/LeadFormReviewBadge';
import ZipFirstField from '../ZipFirstField/ZipFirstField';

const FullScreenLanderHero = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      {' '}
      {/* Flex container to center content */}
      <div className="relative w-full h-full">
        {' '}
        {/* Full width and height */}
        <div className="absolute inset-0">
          <Image
            src={HeroImage2}
            alt="EnergyPal"
            className="object-cover object-bottom -scale-x-100"
            layout="fill"
            priority
          />
          <div className="absolute inset-0 bg-slate-900 bg-opacity-50"></div>
        </div>
        <div className="relative px-4 py-8 md:ml-40 sm:px-6 sm:py-12 lg:py-16 lg:px-8 flex flex-col mt-14 md:mt-0 md:justify-center h-full">
          {' '}
          {/* Center content */} {/* Center text horizontally */}
          <h1 className="text-4xl md:text-6xl text-white font-medium md:w-1/2">
            Get Personalized Home Solar Quotes Online.
          </h1>
          <span className="text-white block mt-4 ml-1">
            {' '}
            {/* Ensure spacing and block display */}
            <LeadFormReviewBadge />
          </span>
          <div className="mt-6">
            <ZipFirstField />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLanderHero;
